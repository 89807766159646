import React from "react";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import classNames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
//import { Spinner } from "../../components/animated/spin";


export const ContactPage = () => {
    return (
        <div className="app-body">
            <AppHeader />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/homebranch.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Contact us</div>
                <div className="page-body">
                    
                </div>
                
            </main>
            <AppFooter />
        </div>
    );
}
