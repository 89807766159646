import React, { useContext } from "react";
import { AppHeader } from "../../components/headers/header";
import "../../styles/home.css";
import { Slideshow } from "../../components/cards/home-slide";
import "../../styles/slides.css"
//import { PicFrame } from "../../components/cards/pic-frame";

import { AppFooter } from "../../components/footers/footer";
import { ServiceCard } from "../../components/cards/service-card";
import { moneyLogos, objectives, serviCardData, whyArray } from "../../_helpers/data.arrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { WhyCard } from "../../components/cards/why-card";
import { Square } from "../../components/animated/animated-conatiner";
import { useAuth } from "../../services/authentication/authentication.context";
import { LanguageContext } from "../../services/language/language.context";
import { homeCaptions } from "../../_helpers/language";

export const HomePage = () => {
    
   const {token, logoutUser, userData, isAuthenticated } = useAuth();
   const { appLanguage } = useContext(LanguageContext);

   return (
        <div className="app-body">
            <div className="info-bar">
                <div className="map-icon">
                    <FontAwesomeIcon icon={faLocationDot} className="loc"/>
                    <div className="loc-text">Head office: Santa, North West, Cameroon</div>
                </div>
                <div className="map-icon">
                    <FontAwesomeIcon icon={faClockFour} className="loc"/>
                    <div className="loc-text">Modays to Saturdays</div>
                </div>
                <div className="map-icon">
                    <FontAwesomeIcon icon={faEnvelope} className="loc"/>
                    <div className="loc-text">support@ayembeunion.org</div>
                </div>
            </div>
            <AppHeader data={userData} auth={isAuthenticated} signout={() => logoutUser(token)}/>
            <div className="frame-cover">
                <Slideshow lang={appLanguage}/>
            </div>
            
            <div className="mid-caption">{homeCaptions.services[appLanguage]}</div>
            <div className="services-wrapper">
                {serviCardData.map((data, i) => {
                    return (
                        <Square key={i}>
                        <ServiceCard 
                            caption={data.caption[appLanguage]}
                            text={data.text[appLanguage]}
                            uri={data.uri}
                            iconPath={data.iconPath}
                        />
                        </Square>
                    )

                })}
            </div>

            
            <div className={classNames({
                "row-inv-box": true
            })}>
                <div className="row-unit">
                    <div className="mid-caption">{homeCaptions.mission[appLanguage]}</div>
                    <div className="img-box">
                        <img src="./images/pics/credit_union.jpg" alt="" title="cred_union" className="box-image" />
                    </div>
                    <div className="unit-caption">{homeCaptions.fininc[appLanguage]}</div>
                    <p className="unit-text">
                        {homeCaptions.incnote[appLanguage]}
                    </p>
                </div>
                <div className="row-unit">
                    <div className="mid-caption">{homeCaptions.objectives[appLanguage]}</div>
                    <div className="rect">
                        { objectives.map((objective, id) => (
                            <div className="obj-unit" key={id}>
                                <FontAwesomeIcon icon={objective.icon} className="obj-icon"/>
                                <span className="obj-text">{objective.text[appLanguage]}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="row-unit">
                    <div className="mid-caption">{homeCaptions.choice[appLanguage]}</div>
                    <div className="black-box">
                        { whyArray.map((point, k) =>
                            (
                                <Square key={k}>
                                <WhyCard
                                    data={point}
                                    lang={appLanguage}
                                />
                                </Square>
                            )
                        )}
                        
                    </div>
                </div>
                
            </div>
            <div className="logos">
                {
                    moneyLogos.map((logo, k) => (
                    <img 
                        className="money-logo" 
                        width={200} 
                        height={100} 
                        alt="" 
                        src={logo.path} 
                        key={k}
                    />
                    ))
                }
            </div>
            <AppFooter />
        </div>
   );
}