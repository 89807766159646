

export const AddImage = (e, path) => {
    e.preventDefault();
        var sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        let Url = path;
        
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            let newNode = document.createElement("div");
            newNode.innerHTML  = `<img src=${Url} alt="" />`; 
            range.insertNode(newNode);
        }
    } 
}


export const boldUnBlod = (e) => {
    e.preventDefault();
    document.execCommand("bold");
}

export const italics = (e) => {
    document.execCommand("italic");
}

export const alignLeft = (e) => {
    document.execCommand("justifyLeft");
}

export const justify = (e) => {
    document.execCommand("justifyFull");
}

export const centerAlign = (e) => {
    document.execCommand("justifyCenter");
}

export const underline = (e) => {
    document.execCommand("underline");
}

export const alignRight = (e) => {
    document.execCommand("justifyRight");
}

export const addOrdList = (e) => {
    document.execCommand("insertUnorderedList");
}

export const addUnOrdList = (e) => {
    document.execCommand("insertOrderedList");
}

export const addLinkUrl = (e) => {
    e.preventDefault();
    let uri = prompt("Enter the link url or uri","https://ayembeunion.org/");
    document.execCommand("createLink", false, uri);
}