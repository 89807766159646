import React from "react";
import { useAuth } from "../../services/authentication/authentication.context";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {

  
    const { isAuthenticated } = useAuth();
    
    //React.useEffect(() => {alert(isAuthenticated)}, [isAuthenticated])

    return (
             isAuthenticated === false  ?  <Navigate to="/login" /> : <Outlet /> 
           );
  
};

export default ProtectedRoute;