import React, { useState } from "react";
//import parse from "html-react-parser"
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import "../../styles/blogs.css";
import { apiUrl, serverUrl } from "../../_helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import { parseTime } from "../../_helpers/parse-time";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { navigate, ellipses } from "../../_helpers/aux-functions";
import { SiteAlert } from "../../components/utils/site-alert";


export const BlogPrevPage = () => {

     const { token, isLoading, userData, logoutUser} = useAuth();

     //get task from param
     const pageTask = new URLSearchParams(window.location.search).get("task");

    const [blogposts, setBlogs] = useState([]);
    const [delId, setDelId] = useState(null);
    const [localLoad, setLoading] = useState(false);

    const getAllBlogs = async () => {
        try {
            const response = await fetch(`${serverUrl}/blogs`,{
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json"
                }
            });
            const blogs = await response.json();
            setBlogs(blogs);
            console.log(blogs);
        } catch (e) {
            console.log({error: `Unable to get blogs! ${e}`})
        }
    }


    const deleteBlogPost = async (id,token) => {   
        try {
            setLoading(true);
            const response = await fetch (`${serverUrl}/deleteblog?id=${id}`,{
                method: "GET",
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`
                },
            });
            const resp = await response.json();
            console.log(resp);
            alert(resp.message);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: "Delete failed! "+e})
        }
    }

    


    React.useEffect(() => { getAllBlogs() }, []);

    return (
        <div className="app-body">
            {(isLoading || localLoad) && <LoadingIndicator />}
            {delId && 
                <SiteAlert
                    caption="Delete blog post"
                    message="Do you wish to delete this blog post? This action is irreversible!"
                    yes="Delete"
                    no="Cancel"
                    yesMethod={() => {
                        deleteBlogPost(delId, token);
                        setDelId(null);
                    }}
                    noMethod={() => setDelId(null)} 
                />
            }
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/news.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Blog & Events</div>
                <div className="page-body">
                    {
                    <div>
                        <div className="mid-caption">Recently posted</div>
                        <div className="blogs-container">
                            {blogposts.map((blogpost, key) => {
                               
                                return (
                                    <div className="blog-unit" key={key}>
                                    <div className="cover-plate">
                                        <div className="padded">
                                            <div 
                                                className="blog-title" 
                                                onClick={() => {window.location.href = `/blogpost?id=${blogpost.id}`}}
                                            >
                                                {ellipses(blogpost.title)}
                                            </div>
                                            <div className="blog-dets">
                                                <span className="det-unit">
                                                    <span className="det-caption">by</span>
                                                    <span className="det-text">{blogpost.names}</span>
                                                </span>
                                                <span className="det-unit">
                                                    <FontAwesomeIcon icon={faClockFour} className="det-caption" />
                                                    <span className="det-text">{parseTime(blogpost.saved_on)}</span>
                                                </span>
                                                <span className="det-unit">
                                                    <button 
                                                        className="task-button"
                                                        onClick={() => {
                                                            pageTask === "update" ? 
                                                            navigate(`/updateblog?id=${blogpost.id}`) :
                                                            setDelId(blogpost?.id);
                                                        }}
                                                    >
                                                    <FontAwesomeIcon 
                                                        icon={pageTask === "update" ? faEdit : faTrashCan} 
                                                        className="det-caption" 
                                                    />
                                                    <span className="det-text">
                                                        {pageTask === "update" ? "Update" : "Delete"}
                                                    </span>
                                                    </button>
                                                    
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="image-block">
                                        <img src={`${apiUrl}/${blogpost.cover_pic}`} alt="" className="block-img"/>
                                    </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </main>
            <AppFooter />
        </div>
    );
}
