import { serverUrl } from "../../_helpers/config"

//user login
export const userAuthentication = (email, password) => {
    return fetch(`${serverUrl}/login`, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        body: JSON.stringify({email, password})
    })
}

export const getUserData = (token) => {
    return fetch(`${serverUrl}/mydata`, {
        method: "GET",
        mode: "cors",
        headers:{
            authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
       
    })
}

//logout or sign out
export const signOut = (token) => {
    return fetch(`${serverUrl}/logout`, {
        method: "GET",
        mode: "cors",
        headers:{
            authorization: `Bearer ${token}`,
            Accept: "application/json"
        }   
    })
}