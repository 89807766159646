import { faCheck, faDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const CreateAccount = ({lang}) => {
    let accountText = [
        {
            en: "Purchase two (2) shares of XAF 10,000 each",
            fr: "Acheter deux (2) actions de 10 000 XAF chacune"
        },
       {
            en: "Pay solidarity fund at XAF 8,000 and building fund at XAF 5,000",
            fr: "Fonds de solidarité à hauteur de 8 000 XAF et fonds de construction à hauteur de 5 000 XAF"
       }
    ];
    return (
          <div className={classNames( "account-container", "containers")}>
             {accountText.map((text, i) => {
                return (
                    <div className="page-line" key={i}>
                        <span className="line-icon"><FontAwesomeIcon icon={faDollar} /></span>
                        <span className="line-text">{text[lang]}</span>
                    </div>
                );
             })}
          </div>
    );
}

export const AccountTypes = ({lang}) => {
      let accountTypes = [
        {
            en: "Savings Account",
            fr: "Compte D'épargne"
        }, 
        {
            en: "Deposit Account",
            fr: "Compte de dépôt"
        }, 
        {
            en: "Salaries Account",
            fr: "Compte de salaires"
        }, 
        {
            en: "Children's Account",
            fr: "Compte des enfants"
        }, 
        {
            en: "Group Account",
            fr: "Compte de groupe"
        }
    ];
    return (
            <div className={classNames( "types-container", "containers")}>
                <div className="box-square">
                    <h1 className="types-header">Types of accounts</h1>
                    {accountTypes.map((type, i) => {
                        return (
                            <div key={i} className="page-line">
                                <span className="line-tick"><FontAwesomeIcon icon={faCheck} /></span>
                                <span className="line-text">{type[lang]}</span>
                            </div>
                        );
                    })}   
                </div>
          </div>
    );
}

export const LoanTypes = ({data, lang}) => {
      
    return (
            <div className={classNames( "types-container", "containers")}>
                <div className="box-square">
                    <h1 className="types-header">Types of Loans</h1>
                    {data.map((type, i) => {
                        return (
                            <div key={i} className="page-line">
                                <span className="line-tick"><FontAwesomeIcon icon={faCheck} /></span>
                                <span className="line-text">{type[lang]}</span>
                            </div>
                        );
                    })}   
                </div>
          </div>
    );
}