import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import classNames from "classnames";

import { AppFooter } from "../../components/footers/footer";
import { AppHeader } from "../../components/headers/header";
import "../../styles/banking.css";
import "../../styles/services.css";
import { AccountTypes, CreateAccount } from "../../components/cards/banking";
import { Square } from "../../components/animated/animated-conatiner";
import { useAuth } from "../../services/authentication/authentication.context";
import { LanguageContext } from "../../services/language/language.context";
import { accountsText } from "../../_helpers/language";

const captions = {
    page: {
        en: "Accounts & Banking",
        fr: "Comptes & Opérations Bancaires"
    },
    savings: {
        en: "Savings Account",
        fr: "Compte D'épargne"
    },
    term: {
        en: "Term Account",
        fr: "Compte à terme"
    },
    deposit:{
        en: "Deposit Account",
        fr: "Compte de dépôt"
    },
    salaries:{
        en: "Salaries Account",
        fr: "Compte de salaires"
    },
    children: {
        en: "Children's Account",
        fr: "Compte des enfants"
    },
    create: {
        en: "Create an account",
        fr: "Créer un compte"
    },
    group: {
        en: "Group Account",
        fr: "Compte de groupe"
    }
}

export const BankingPage = ({ route }) => {

    const {token, logoutUser, userData, isAuthenticated } = useAuth();
    const { appLanguage } = useContext(LanguageContext);

    useEffect(() => {
         let query = new URLSearchParams(window.location.search).get("sub");
         if (query) {
            window.location.href = `#${query}`;
            console.log(route);
         }else return
         
    },[route]);
    return (
    <div>
         <AppHeader data={userData} auth={isAuthenticated} signout={() => logoutUser(token)}/>
        <main className={classNames({"banking-page": true, "app-main": true})}>
            <div className="page-cover" >
                <div className="cover-layer" />
                <div className="page-pic">
                    <img src="./images/pics/group-acct-cut.png" className="home-pic" alt="home" />
                </div>
            </div>
            
        <div className="pic-caption">{captions.page[appLanguage]}</div>
        <div className="page-body">
            <h1 className="page-header">{captions.create[appLanguage]}</h1>
            <CreateAccount lang={appLanguage}/>
            <AccountTypes lang={appLanguage} />
            <Square>
                <div className="account-details">
                        <span className="topic-caption">
                                {captions.savings[appLanguage]}
                        </span>
                    <div className="topic-container">
                        <div className="topic-image-box">
                                <img src="./images/pics/savings.jpg" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts" id="savings-account">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l11[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l12[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l13[appLanguage]} </span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </Square>

            <Square>
                <div className="account-details">
                    <span className="topic-caption" id="deposit-account">
                            {captions.deposit[appLanguage]} 
                    </span>
                    <div className="topic-container deposit-topic">
                        <div className="topic-image-box">
                            <img src="./images/pics/piggy.jpg" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l21[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l22[appLanguage]}</span>
                            </div>
                        </div>
                    </div>
                
                </div>
            </Square>
            <Square>    
                <div className="account-details">
                    <span className="topic-caption">
                        {captions.term[appLanguage]} 
                    </span>
                    <div className="topic-container">
                        <div className="topic-image-box">
                            <img src="./images/pics/short-or-long-term-deposit_original.webp" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l31[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l32[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l33[appLanguage]}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Square>
            
            <Square>
                <div className="account-details" id="children-account">
                    <div className="topic-caption">
                            {captions.children[appLanguage]} 
                    </div>
                    <div className="topic-container">
                        <div className="topic-image-box">
                            <img src="./images/pics/children-account.jpg" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l41[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l42[appLanguage]}</span>
                            </div>
                        </div>
                    </div>    
                </div>
            </Square>

            <Square>    
                <div className="account-details" id="children-account">
                    <div className="topic-caption">
                            {captions.group[appLanguage]} 
                    </div>
                    <div className="topic-container">
                        <div className="topic-image-box">
                            <img src="./images/pics/grp-account.png" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l51[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l52[appLanguage]}</span>
                            </div>
                        </div>
                    </div>    
                </div>
            </Square>
            <Square>    
                <div className="account-details" id="salaries-account">
                    <div className="topic-caption">
                            {captions.salaries[appLanguage]}
                    </div>
                    <div className="topic-container">
                        <div className="topic-image-box">
                            <img src="./images/pics/salaries.jpg" alt="" className="topic-img" />
                        </div>
                        <div className="account-facts">
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l61[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-text">{accountsText.l62[appLanguage]}</span>
                            </div>
                            <div className="fact-box">
                                <span className="fact-bullet"><FontAwesomeIcon icon={faSackDollar} color="#2a4d5f" /></span>
                                <span className="fact-dets">{accountsText.l63[appLanguage]}</span>
                            </div>
                        </div>
                    </div>    
                </div>
            </Square>
        </div>
        </main>
        <AppFooter />
    </div>
    );
}