import React, { useState, useRef} from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faClose, } from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { BlogToolBar } from "../../components/forms/toolbar";
import { serverUrl, apiUrl } from "../../_helpers/config";
import { AddImage } from "../../_helpers/formatting";
import "../../styles/infos.css";
import "../../styles/blogs.css";
import { getUrlParams } from "../../_helpers/aux-functions";


export const UpdateBlog = () => {
    const { token, isLoading, userData, logoutUser} = useAuth();

    const id = getUrlParams("id");
  
    const inptBox = useRef();

    const [images, setImages] = useState([]);
    const [imgeObjects, setImageObjects] = useState([]);
    const [preview, setPreview] = useState(false);
    const [htmlContent, setHtmlContent] = useState(null);
    const [title, setTitle] = useState(null);
    const [cover, setCover] = useState(null); //$

    const [newCover, setNewCover] = useState(null);

    const coverRef = React.useRef();
    const contentRef = React.useRef(); 


     const previewBlog = async () => {
        setPreview(true);
        let text = document.getElementById("content").innerHTML;
        if (text === null || text === undefined || text === "") {
            return alert("Please write something to preview!");
        }
        text = text.replace(/\n/g, "<br/>");
        let textBody = `<p>${text}</p>`;

        setHtmlContent(textBody.toString());
        
        document.getElementById("preview").innerHTML = "";
        document.getElementById("preview").innerHTML = textBody;

        const imagesArray = getAllImages();
        imagesArray.forEach((arr) => {
            if (!images.includes(arr.currentSrc)) {
                setImages(() => images.filter((i) => i !== arr.currentSrc));
                setImageObjects(() => (
                    imgeObjects.filter((obj) => URL.createObjectURL(obj) !== arr.currentSrc)
                ))
            }
        });
        
    }

    const getAllImages = () => {
        const images = document.getElementById("preview").querySelectorAll("img");
        return images;  
    }

    const getBlogById = async (id) => {
        try {
            const response = await fetch(`${serverUrl}/getbyhash?id=${id}`);
            const res = await response.json();
            console.log({blog: res});

            setCover(`${apiUrl}/${res.cover_pic}`);
            const parsedBody = parse(res.content);
            setTitle(res.title);
            setHtmlContent(parsedBody);
           
        } catch (e) {
           console.log({error: `Unable to load blog! ${e}`}); 
        }
    }

    React.useEffect(() => {
        getBlogById(id);
    }, [id]);

    

    const updateBlogData = async () => {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", htmlContent);
        //return console.log({title, htmlContent})
        /** check if cover added or not */
        if (newCover) {
            formData.append("cover", newCover);
        }

        try {
            const response = await fetch(`${serverUrl}/updateblog?id=${id}`,{ 
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: formData
            });
            const data = await response.json();
            console.log(data);
            return data;
        } catch (e) {
            console.log({error: `HTML data save error! ${e}`});
        }
    }

     const addIamge = async (e) => {
        e.preventDefault();
        const imgFile = e.target.files[0];
    
        const imageData = new FormData();
        imageData.append("image", imgFile);
        try {
            const response = await fetch(`${serverUrl}/addblogpic`,{
                method: "POST",
                mode: "cors",
                headers:{
                    Accept: "application/json"
                },
                body: imageData
            });
            const res = await response.json();
            console.log(res);
            const { path } = res
            AddImage(e, path);
            return res;
        } catch (e) {
            console.log({error: `Image save error! ${e}`});
        }
    
        
       
    }


    const uploadBlogData = async () => {
        try {
            const res = await updateBlogData();
            console.log(res);
            //  
        } catch (e) {
               console.log({error: `Operation failed! ${e}`})
        }
    }

    const changeCover = (e) => {
        e.preventDefault();
        let imgfile = e.target.files[0];
        setNewCover(imgfile);
        document.getElementById("cover-img").src = URL.createObjectURL(imgfile);
        document.getElementById("cover-img-prev").src = URL.createObjectURL(imgfile);
    }

    const clickFile = () => {
        coverRef.current.click();
    }


    React.useEffect(() => {
        console.log({token, userData})
    }, [token, userData]);
    
    return (
        <div className="app-body">
           <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("info-page", "app-main")}>   
                <div 
                    className={classNames({"page-body": true})} 
                    style={{display: "flex", justifyContent: "center"}}
                > 
                    <div className="mid-caption">Update a blog post</div>
                    <div className={classNames({"blog-form": true, "hidden": preview })} ref={inptBox}>
                        <BlogToolBar addImage={(e) => addIamge(e)}/>
                        <div className="cover-img" id="cover">
                            <img src={cover} id="cover-img" className="cover-picture" alt="" />
                            <input 
                                type="file" 
                                className="select-cover" 
                                onChange={(e) => changeCover(e)}
                                ref={coverRef} 
                            />
                            <button 
                                className={classNames("file-select", "tbr-btn")} 
                                onClick={() => clickFile()}
                            >
                                <span className="lbl">Add cover image</span>
                                <FontAwesomeIcon icon={faImage} className="file-img" />
                            </button>
                        </div>
                        <input 
                            type="text" 
                            id="blog-title"
                            value={title} 
                            className="title"
                            multiple={true}
                            placeholder="Enter blog title here"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <div contentEditable={true} className="blog-input" id="content" ref={contentRef} >
                            {htmlContent}
                        </div>
                        <button 
                            className="blog-create-btn" 
                            onClick={() =>{
                                 setPreview(true);
                                 previewBlog();
                            }}
                        >
                            Preview blog
                        </button>
                    </div>
                    <div className={classNames({"blog-form": true, "hidden": !preview})}>
                        <button 
                            className={classNames("tbr-btn", "cls")} 
                            onClick={() => {
                                setPreview(!preview);
                                document.getElementById("content").innerHTML = htmlContent;
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} color="#ffffff" />
                        </button>
                        <img src={cover} id="cover-img-prev" className="cover-picture" alt="" />
                        <div className="title">{title}</div>
                        <div className="blog-input" id="preview"></div>
                        <button className="blog-create-btn" onClick={() => uploadBlogData()}>
                            Save blog
                        </button>
                    </div>
                    
                </div>
            </main>
            <AppFooter />
        </div>
    );
}
