import React from "react";

export const NotFound = () => {
    return (
        <div 
            className="center-page"
        >
            <div className="large-text">
                <span>404</span>
                <br/>
                <span>Not found!</span>
            </div>
        </div>
    );
}