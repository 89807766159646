import classNames from "classnames"

export const AppFooter  = (props) => {
    return (
        <footer>
                <div className="top-footer">
                <div className="quick-links">
                    <div className="link-caption">Quick Links</div>
                    <div className="link-section">
                        <a href="/infos" className="link-text">About us</a>
                        <a href="/banking" className="link-text">Accounts and banking</a>
                        <a href="/blog" className="link-text">Blog</a>
                        <a href="/loans" className="link-text">Loans</a>
                        <a href="/contactus" className="link-text">Contact us</a>
                    </div>
                </div>
                <div className="aicco-info">
                    <div className="info-box">
                        <div className="info-text">Affiliated to </div>
                        <div className="logo">
                            <a href="https://rainbowcam.org" > <img src="./images/pics/rainbowcam-logo-1.png" width="200" height="100" alt="" /></a>
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="info-icon">
                        <img src="./images/svgs/location.svg" width="80" height="80" alt="" />
                        </div>
                        <div className="info-caption">
                        <div className="title">Head office:</div>
                        <hr />
                        <div className="location">North West Region, Santa, besides ENEO office, Santa Park.</div>
                        </div>
                    </div>
                    
                    <div className="info-box"></div>
                </div>
                </div>
                <div className="copy-bottom">
                <span className={classNames("copy","bottom-small")}>
                    Copyright &copy; AICCO <span className="year" id="this-year"></span>
                </span>
                <span className={classNames("privacy", "bottom-small")}>Privacy policy</span>
                </div>
            </footer>
    );
}