import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import "../../styles/infos.css";
import "../../styles/dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faUpload } from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../_helpers/config";
//import { navigate } from "../../_helpers/aux-functions";




export const EditAccount = () => {
    const { token, isLoading, userData, logoutUser} = useAuth();

    const [image, setImage] = useState(null);
    const [userName, setUserName] = useState();
    const [names, setNames] = useState();
    const [email, setEmail] = useState();

    useEffect(() => {
        setEmail(userData?.email);
        setNames(userData?.names);
        setUserName(userData?.username);
    },[userData]);

    const updateUser = async () => {
        try {
            const formData = new FormData();
            formData.append("names", names);
            formData.append("email", email);
            formData.append("username", userName);
            if (image){
                formData.append("avatar", image);
            }
            const response = await fetch(`${serverUrl}/updateaccount`,{
                method: "POST",
                headers:{
                    Accept: "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: formData
            });
            const res = await response.json();
            console.log(res);
            if (res.error){
                alert(res.error);
                console.log(res);
                return;
            }
            alert(res.message);
            
        } catch (e) {
            console.log({error: `Unable to update account data! ${e}`});
        }
    }

    const fileRef = useRef();

    const changeImage = (e) => {
        let imageFile = e.target.files[0];
         document.querySelector(".admin-img").src = URL.createObjectURL(imageFile);
         setImage(imageFile);
    }

    React.useEffect(() => {
        console.log({token, userData})
    }, [token, userData]);

    return (
        <div className="app-body">
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("admin-page", "app-main")}>
               <div className="data-container">
                  <div className="admin-avatar">
                    <img src={userData?.avatar} alt="admin" className="admin-img" />
                    <input 
                        type="file" 
                        className="edit-avatar" 
                        ref={fileRef}
                        onChange={(e) =>{ changeImage(e)}} 
                    />
                    <button className="pill-btn" onClick={() => fileRef.current.click()}>
                        <FontAwesomeIcon icon={faImage} />
                        <span className="edit-cap">Change picture</span>
                    </button>
                  </div>
                  <div className="admin-infos">
                    
                    <div className="info-data">
                        <input 
                            className="data-input" 
                            value={names}
                            onChange={(e) => setNames(e.target.value)}  
                        />
                    </div>
                    <div className="info-data">
                        <input 
                            className="data-input" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="info-data">
                        <input 
                            className="data-input" 
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)} 
                        />
                    </div>
                  </div>
                  <div className="update-btn">
                    <button className="pill-btn" onClick={() => updateUser()}>
                        <FontAwesomeIcon icon={faUpload} />
                        <span className="edit-cap">Submit</span>
                    </button>
                  </div>
               </div>
            </main>
            <AppFooter />
        </div>
    );
}
