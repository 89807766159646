import React from "react";
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import "../../styles/infos.css";
import "../../styles/dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "../../_helpers/aux-functions";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export const AdminAccount = () => {
    const { token, isLoading, userData, logoutUser} = useAuth();

    React.useEffect(() => {
        console.log({token, userData})
    }, [token, userData]);

    return (
        <div className="app-body">
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("admin-page", "app-main")}>
               <div className="data-container">
                  <div className="admin-avatar">
                    <img src={userData?.avatar} alt="admin" className="admin-img" /> 
                  </div>
                  <div className="admin-infos">
                    <div className="info-data">{userData?.names}</div>
                    <div className="info-data">{userData?.email}</div>
                    <div className="info-data">{userData?.username}</div>
                  </div>
                  <div className="update-btn">
                    <button className="pill-btn" onClick={() => navigate("/updateaccount")}>
                        <FontAwesomeIcon icon={faEdit} />
                        <span className="edit-cap">Edit</span>
                    </button>
                  </div>
               </div>
            </main>
            <AppFooter />
        </div>
    );
}
