export const FormSubmitButton = ({ width, caption, onPress}) => {
    return (
        <div className="button-container"
            style={{width: width}}
        >
            <button 
                className="form-button"
                onClick={onPress}
            >
                {caption}
            </button>
        </div>
    );
}