import classNames from "classnames";
import "../../styles/loader.css";

export const LoadingIndicator = () => {
    return (
        <div className={classNames({"loader-page": true})}>
            <img 
                src="./images/pics/loading.png" 
                className="loader" 
                alt="loader"
                width={50}
                height={50}
            />
        </div>
    );
}