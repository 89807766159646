export const WhyCard = ({data, lang}) => {
   
    return (
        <div className="why-point">
            <img src={data.icon} alt="reliable" className="why-icon" />
            <div className="why-topic">
                <span className="why-text">{data.caption[lang]}</span>
                <span className="why-context">{data.text[lang]}</span>
            </div>
            
        </div>
    );
}