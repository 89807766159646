import React, { createContext, useContext, useState} from "react";

import { getUserData, signOut, userAuthentication } from "./authentication.services";

export const AuthenticationContext = createContext();

export const AuthenticationContextProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(() => null);
    const [userData, setUserData] = useState(null);

    /**
     * 
     * @param {*} email 
     * @param {*} password 
     */
    const loginUser = async (email, password) => {
        try {
            setIsLoading(true);
            const response = await userAuthentication(email, password);
            const respData = await response.json();
            if (respData.error) {
                setIsLoading(false);
                return alert(respData.error);
            }
           
            setIsLoading(false);
            const { accessToken, data } = await respData;
             setIsAuthenticated(true);
            localStorage.setItem("token", accessToken);
            
            setUserData(data);

             return window.location.href = "/admin"
        } catch (e) {
            setIsLoading(false);
            console.log({error: e});
        }
    }

    const loadUserData = async (token) => {
        try { 
            const response = await getUserData(token);
            const data = await response.json();
            console.log(data);
            return data;
        } catch (e) {
            console.log({error: e});
        }
    }





    React.useEffect(() => {
        const tokenPromise = new Promise((resolve, reject) => {
            const tk = localStorage.getItem("token");
            if (tk) {
                resolve(tk);
            }else{
                reject("No token");
            }
        });
        ( async() => {
            try {    
                const tok = await tokenPromise;
                setToken(() => tok);
               if (token) {
                    let user = await loadUserData(token);
                    if (user.error){
                        console.log({error: `${user.error}`});
                        setIsAuthenticated(() => false);
                        if (window.location.pathname === "/admin") {
                            window.location.href = "/login";
                        }
                        return;
                    }
                    setUserData(user);
                    setIsAuthenticated(() => true);
                }
              
            } catch (error) {
                console.log({error});
            }
        })();
    }, [token]);

    const logoutUser = async(token) => {
        setIsLoading(true);
        try {
            const response = await signOut(token);
            const res = await response.json();
            console.log(res);
            setIsLoading(false);
            window.location.href = "/login";
        } catch (error) {
            setIsLoading(false);
            console.log({error: "No token found!"});
        }
    }

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                loginUser,
                logoutUser,
                loadUserData,
                isLoading,
                userData,
                token
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthenticationContext);
}