import { useContext, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBlog, faClose, faGlobe, faHouse, faInfoCircle, faMoneyBill1Wave, faPowerOff, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

import "../../styles/header.css";
import { LanguageContext } from "../../services/language/language.context";
import { HeaderLabels } from "../../_helpers/language";
import { DoubleSpace } from "../utils/spacers";

const navigateTo = (url) => {
    window.location.href = url;
}


export const AppHeader = ({data, auth, signout}) => {

    const { appLanguage, switchAppLanguage } = useContext(LanguageContext);

    const [showMenu, setShowMenu] = useState(false);

    const isCurr = (uri) => window.location.pathname.toString() === uri;


    const avatar = `${data?.avatar}`;
    
    return(
        <header className="transparent-header">
                <section className="title-section">
                    <article className="logo-section"> 
                        <img src="/images/logos/aicco-logo.jpg" alt="" className="header-logo" />
                    </article>
                   
                </section>
                <article className="menu_mobile_view">
                    <button className="show-drop-menu" onClick={() => setShowMenu(!showMenu)}>
                      <span className={classNames({ "bars": true, "hide": showMenu})}><FontAwesomeIcon icon={faBars} /></span>
                      <span className={classNames({"close": true, "hide": !showMenu })}><FontAwesomeIcon icon={faClose} /></span>
                    </button>
                </article>
                <button 
                    className="fr" 
                    id="fr" 
                    onClick={() => switchAppLanguage()}
                > 
                    <FontAwesomeIcon icon={faGlobe} />
                    <DoubleSpace />
                    {HeaderLabels.lang[appLanguage]} 
                </button>
                <div className={classNames({
                    "menu-tabs": true,
                    "conceal" : showMenu === false
                })} >
                   {auth && 
                    <a href="/admin" className={classNames("avatar-frame")}>
                        <img src={avatar} id="admin-img" className="r-img" alt="" />
                    </a>
                    }
                    <a href="/" className="menu_link">
                        <button className={classNames({"menu_button": true, "current": isCurr("/")})}>
                            <span className="tab-icon" > <FontAwesomeIcon icon={faHouse} /> </span>
                            {HeaderLabels.home[appLanguage]}
                        </button>
                    </a>
                    <a href="/banking" className="menu_link">
                      
                        <button className={classNames({"menu_button": true, "current": isCurr("/banking")})}>
                            <span className="tab-icon" > <FontAwesomeIcon icon={faSackDollar} /> </span>
                            {HeaderLabels.banking[appLanguage]}
                        </button>
                    </a>
                     <a href="/infos" className="menu_link">
                        <button className={classNames({"menu_button": true, "current": isCurr("/infos")})}>
                            <span className="tab-icon" ><FontAwesomeIcon icon={faInfoCircle} /></span>
                            {HeaderLabels.about[appLanguage]}
                        </button>
                    </a>
                     <a href="/loans" className="menu_link">
                        <button className={classNames({"menu_button": true, "current": isCurr("/loans")})}>
                            <span className="tab-icon" ><FontAwesomeIcon icon={faMoneyBill1Wave} /></span>
                            {HeaderLabels.loans[appLanguage]}
                        </button>
                    </a>
                    
                    <a href="/blog" className="menu_link"> 
                        <button className={classNames({"menu_button": true, "current": isCurr("/blog")})}>
                            <span className="tab-icon" ><FontAwesomeIcon icon={faBlog} /></span>
                            {HeaderLabels.blog[appLanguage]}
                        </button>
                    </a>
                    <div className="auth">
                       { !auth ?  
                        <button className="auth_button login" id="login" onClick={() => navigateTo("/login")}>
                            <span className="tab-icon"><FontAwesomeIcon icon={faUser} /></span>
                            <span className="button-text">
                                {HeaderLabels.login[appLanguage]}
                            </span>
                        </button> :
                        <button className="auth_button logout" id="logout" onClick={signout}>
                            <span className="tab-icon"><FontAwesomeIcon icon={faPowerOff} /></span>
                            <span className="button-text">
                                {HeaderLabels.logout[appLanguage]}
                            </span>
                        </button>
                        }
                    </div>
                  </div>
                 
             </header>
    )
}