import { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import "../../styles/blogs.css";
import "../../styles/infos.css";

import { useAuth } from "../../services/authentication/authentication.context";
import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { apiUrl, serverUrl } from "../../_helpers/config";
import { parseTime } from "../../_helpers/parse-time";

export const EventsPage = () => {
    const { userData, token, logoutUser } = useAuth();

    const [event, setEvent] = useState(null);

    const id = new URLSearchParams(window.location.search).get("eventid");

    const getGalleryData = async (eventId) => {
        try {
            const response = await fetch(`${serverUrl}/loadgallerydata?id=${eventId}`,{
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json"
                }
            });
            const eventData = await response.json();
            console.log(eventData);
            return (eventData);
        } catch (e) {
            console.log(`Something went wrong! ${e}`);
        }
    }

    useEffect(() => {
        ( async() => {
            const data = await getGalleryData(id);
            setEvent(data);
        })();
    },[id])
    return (
         <div className="app-body">
         <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/news.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Event details</div>
                <div className="page-body"> 
                    <div className="blog-details">
                        <img src={`${apiUrl}/${event?.picture}`} alt="" className="blog-image" />
                        <div className="blog-det-title">{event?.detail}</div>
                        <div className="blog-data">
                            <span className="data-unit">Date: {parseTime(event?.saved_on)} </span>
                            <div className="separator" />
                            <span className="data-unit">Author: {event?.poster}</span>
                        </div>
                        <hr/>
                        
                        <div className="share-prompt">
                            <div>
                                <span className="share-icon">
                                    <FontAwesomeIcon icon={faShareNodes} />
                                </span>
                                <span className="prompt-caption">Share</span>  
                            </div>
                            <span className="propt-buttons">
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faXTwitter} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                
        </main>
        <AppFooter />
        </div>
    );
}