import React, { useContext } from "react";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { branchData } from "../../_helpers/data.arrays";
import { Spinner } from "../../components/animated/spin";
import { aboutText } from "../../_helpers/language";
import { LanguageContext } from "../../services/language/language.context";


export const InfosPage = () => {
    const { appLanguage } = useContext(LanguageContext);
    return (
        <div className="app-body">
            <AppHeader />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/homebranch.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">{aboutText.page[appLanguage]}</div>
                <div className="page-body">
                    <div className="info-unit">
                        <div className="page-caption">{aboutText.cr[appLanguage]}</div>
                        <div className="about-black-bg"> 
                            <div className="about-text">
                                <div className="bg-cover">
                                    {aboutText.info[appLanguage]}
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="page-caption">{aboutText.br[appLanguage]}</div>
                    <div className="branch-box">
                        { branchData.map((branch) =>(
                            <Spinner>
                            <div className="branch-unit">
                                <div className="branch-pic">
                                    <img src={branch.image} className="branch-img" alt="branch" />
                                </div>
                                <div className="branch-info">
                                    <div className="sqr">
                                    <span className="branch-cap">{branch.name[appLanguage]}</span>
                                    <div className="info-data">
                                        <div className="info-icon">
                                            <FontAwesomeIcon color="#daa520" icon={faLocationDot} className="info-icon" />
                                        </div>
                                        <div className="info-more">
                                            <span className="text-caption">Location</span>
                                            <span className="info-dets">{branch.location}</span>
                                        </div>
                                    </div>
                                    <div className="info-data">
                                        <div className="info-icon">
                                            <FontAwesomeIcon color="#daa520" icon={faPhone} className="info-icon" />
                                        </div>
                                        <div className="info-more">
                                            <span className="text-caption">Contact</span>
                                            <span className="info-dets">{branch.contact}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </Spinner>
                        ))}
                    </div>
                </div>
                
            </main>
            <AppFooter />
        </div>
    );
}
