import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "../../styles/slides.css";
import classNames from 'classnames';
import { Square } from '../animated/animated-conatiner';

const fadeImages = [
    {
        url: "/images/pics/staff.jpg",
        caption: {
          en: "Our staff is always available and enthusiastic to serve members",
          fr: "Notre personnel est toujours disponible et enthousiaste à servir les membres"
        }
    },
    {
        url: "/images/pics/agriculture.jpg",
        caption: {
          en: "AICCO offers agricultural loans",
          fr: "AICCO propose des prêts agricoles"
        }
    },
    {
        url: "/images/pics/school-fees-loan.jpg",
        caption: {
          en: "AICCO offers School fees loans",
          fr: "AICCO propose des prêts pour les frais de scolarité"
        }
    }
];


export const Slideshow = ({lang}) => {

  return (
    <div className="slide-container">
      <Slide
        transitionDuration={500} 
        duration={5000} 
        defaultIndex={0}
        cssClass='fade-class'
        canSwipe
        autoplay= {true}
      >
        {fadeImages.map((fadeImage, index) => (
          <div className='slide-frame' key={index}>
              <img className="slide-pics" src={fadeImage.url} alt='slide-img' />
              <div className="slide-caption">
              </div>
              <div 
                id={index} 
                className={classNames({
                  'caption-text': true,
                  "anim": true,
                  "slide-up": true
                })}
              >
                  <Square>
                  <span>{fadeImage.caption[lang]}</span>
                  <button className='more-btn'>Learn more</button>
                  </Square>
              </div>
          </div>
        ))}
      </Slide>
    </div>
  )
}