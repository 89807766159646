import React from "react";
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import "../../styles/infos.css";
import "../../styles/dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faImage, faPlusCircle, faTrashCan, faUserTie } from "@fortawesome/free-solid-svg-icons";


export const AdminPage = () => {
    const { token, isLoading, userData, logoutUser} = useAuth();

    React.useEffect(() => {
        console.log({token, userData})
    }, [token, userData]);

    const navigate = (uri) => { window.location.href = uri};
    
    return (
        <div className="app-body">
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("info-page", "app-main")}>
               <div className="dashboard" >
                    <div className="group-box">
                        <div className="group-caption">Blog Operations</div>
                        <div className="bt-group"> 
                            <button 
                                className={classNames("dash-button", "bt_blue")}
                                onClick={() => navigate("/newblog")}
                            >
                            <FontAwesomeIcon icon={faPlusCircle} className="dash-icon" />
                            <span className="btn-label">New blog</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_vl")}
                                onClick={() => navigate(`/blogpreview?task=update`)}
                            >
                            <FontAwesomeIcon icon={faEdit} className="dash-icon" />
                            <span className="btn-label">Update blog</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_red")}
                                onClick={() => navigate(`/blogpreview?task=delete`)}
                            >
                            <FontAwesomeIcon icon={faTrashCan} className="dash-icon" />
                            <span className="btn-label">Delete blog</span>
                            </button>
                        </div>
                    </div>
                    <hr/>
                    <div className="group-box">
                        <div className="group-caption">Account Operations</div>
                        <div className="bt-group"> 
                            <button 
                                className={classNames("dash-button", "bt_gr")}
                                onClick={() => navigate("/account")}
                            >
                            <FontAwesomeIcon icon={faUserTie} className="dash-icon" />
                            <span className="btn-label">My account</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_blk")}
                                onClick={() => navigate("/updateaccount")}
                            >
                            <FontAwesomeIcon icon={faEdit} className="dash-icon" />
                            <span className="btn-label">Update account</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_red")}
                                onClick={() => null}
                            >
                            <FontAwesomeIcon icon={faTrashCan} className="dash-icon" />
                            <span className="btn-label">Delete account</span>
                            </button>
                        </div>
                    </div>
                    <hr/>
                    <div className="group-box">
                        <div className="group-caption">Gallery Operations</div>
                        <div className="bt-group"> 
                            <button 
                                className={classNames("dash-button", "bt_gl")}
                                onClick={() => navigate("/newpic")}
                            >
                            <FontAwesomeIcon icon={faImage} className="dash-icon" />
                            <span className="btn-label">New image</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_blk")}
                                onClick={() => navigate("/previewpic?task=update")}
                            >
                            <FontAwesomeIcon icon={faEdit} className="dash-icon" />
                            <span className="btn-label">Update image</span>
                            </button>
                            <button 
                                className={classNames("dash-button", "bt_red")}
                                onClick={() => navigate("/previewpic?task=delete")}
                            >
                            <FontAwesomeIcon icon={faTrashCan} className="dash-icon" />
                            <span className="btn-label">Delete image</span>
                            </button>
                        </div>
                    </div>
               </div>
            </main>
            <AppFooter />
        </div>
    );
}
