import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faImage } from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../../_helpers/config";

import "../../styles/infos.css";
import "../../styles/blog-create.css";
import "../../styles/gallery.css";
import { serverUrl } from "../../_helpers/config";

export const UpdateImage = () => {
    const { token, isLoading, userData, logoutUser} = useAuth();

    const eventId = new URLSearchParams(window.location.search).get("id");
  
    const inptBox = useRef();

    
    const [imageFile, setImageFile] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [imageUri, setImageUri] = useState(null);
    const [detail, setDetail] = useState(null);
    const [clickable, setClickable] = useState(false);
    
    

    const coverRef = React.useRef(); 

    
    const addImage = (e) => {
        let image = e.target.files[0];
        setImageFile(image);
        setImageUri(URL.createObjectURL(image));
        setClickable(true);
    }

    const clickFile = (e) => {
        coverRef.current.click();
    }

    
    const data = {
        image: imageFile,
        detail,
    }

    const createFormData = (d) => {
        const formData = new FormData();

        formData.append("image", d?.image);

        if (d.detail) {
            formData.append("detail", d?.detail);
        }

        return formData;
    }

    const getGalleryData = async (eventId) => {
        try {
            const response = await fetch(`${serverUrl}/loadgallerydata?id=${eventId}`,{
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json"
                }
            });
            const eventData = await response.json();
            console.log(eventData);
            return (eventData);
        } catch (e) {
            console.log(`Something went wrong! ${e}`);
        }
    }

    const updateImage = async (d, tk) => {
        try {
           const response = await fetch(`${serverUrl}/updateimage?id=${eventId}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "authorization": `Bearer ${tk}`
            },
            body: createFormData(d)
           });
           const res = await response.json();
           console.log(res);
           alert(res.message); 
        } catch (e) {
            console.log({error: e})
        }
    } 

    useEffect(() => {
        ( async() => {
            const data = await getGalleryData(eventId);
            setEventData(data);
        })();
    },[eventId]);
   
    return (
        <div className="app-body">
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("info-page", "app-main")}>   
                <div 
                    className={classNames({"page-body": true})} 
                    style={{display: "flex", justifyContent: "center"}}
                > 
                    <div className={classNames({"blog-form": true })} ref={inptBox}>
                   
                        <div className="cover-img" id="cover">
                            <img src={imageUri || `${apiUrl}/${eventData?.picture}`} id="cover-img" className="cover-picture" alt="" />
                            <input 
                                type="file" 
                                className="select-cover"
                                onChange={(e) => addImage(e)}
                                ref={coverRef} 
                            />
                            <button 
                                className={classNames("file-select", "tbr-btn")} 
                                onClick={() => clickFile()}
                            >
                                <span className="lbl">Add cover image</span>
                                <FontAwesomeIcon icon={faImage} className="file-img" />
                            </button>
                        </div>
                        <textarea 
                            type="text" 
                            id="image-detail" 
                            className="title" 
                            placeholder="Enter image title here"
                            onChange={(e) => setDetail(e.target.value)}
                            value={detail || eventData?.detail} 
                        />
                        
                        <button 
                            className={classNames({"blog-create-btn": true, "blured": !clickable})} 
                            onClick={() =>{ updateImage(data, token)}}
                            disabled={!clickable}
                        >
                            Update Image
                        </button>
                    </div>
                    
                </div>
            </main>
            <AppFooter />
        </div>
    );
}
