import React from 'react';
/** app imports */
import { BrowserRouter, Routes, Route } from 'react-router-dom';

/**local imports */
import { LanguageContextProvider } from './services/language/language.context';
//styles
import "./styles/footer.css";
import "./styles/header.css";
import "./styles/address-bar.css";
import "./index.css";

import { AuthenticationContextProvider } from './services/authentication/authentication.context';

import { HomePage } from './features/home/home';
import { BankingPage } from './features/banking/banking';
import { LoginPage } from './features/auth/login';
import { SendRecoveryMail } from './features/auth/recoverymail';
import { InfosPage } from './features/infos/infos';

import { AdminPage } from './features/admin/admin';
import { NotFound } from './features/utils/not-found';
import ProtectedRoute from './components/utils/private-route';
import { BlogPage } from './features/blogs/blogs';
import { LoansPage } from './features/loans/loans';
import { CreateNewBlog } from './features/blogs/create-blog';
import { UpdateBlog } from './features/blogs/update-blog';
import { BlogPost } from './features/blogs/blog-post';
import { BlogPrevPage } from './features/blogs/update-prev';
import { AdminAccount } from './features/admin/admin.account';
import { EditAccount } from './features/admin/admin.update';
import { ContactPage } from './features/contact/contact';
import { EventsPage } from './features/blogs/event-post';
import { CreateNewImage } from './features/blogs/newimage';
import { GalleryEditPage } from './features/gallery/preview-pics';
import { UpdateImage } from './features/gallery/update-pics';


function App() {
  return (
    <BrowserRouter>
      <LanguageContextProvider>
        <AuthenticationContextProvider>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/banking" element={<BankingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route element = {<ProtectedRoute />} >
                <Route path="/admin" element={<AdminPage />} />
                <Route path='/newblog' element={<CreateNewBlog />} />
                <Route path='/updateblog' element={<UpdateBlog />} />
                <Route path='/newpic' element={<CreateNewImage />} />
                <Route path='/previewpic' element={<GalleryEditPage />} />
                <Route path='/editimage' element={<UpdateImage />} />
            </Route>
            <Route path='/*' element={<NotFound />} />
            <Route path='/loans' element={< LoansPage />} />
            <Route path="/recoverymail" element={<SendRecoveryMail />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/blogpreview' element={<BlogPrevPage />} />
            <Route path='/updateblog' element={<UpdateBlog />} />
            <Route path="/infos" element={<InfosPage />} />
            <Route path='/account'element={<AdminAccount />} />
            <Route path='/updateaccount' element={<EditAccount />} />
            <Route path="/blogpost" element={<BlogPost />} />
            <Route path='/contactus' element={<ContactPage />} />
            <Route path='/eventdetail' element={<EventsPage />} />
          </Routes>
        </AuthenticationContextProvider>
      </LanguageContextProvider>
    </BrowserRouter>
  );
}

export default App;
