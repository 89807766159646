import React, { useContext } from "react";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import classNames from "classnames";
import { LoanTypes } from "../../components/cards/banking";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSeedling, faSuitcase } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "../../components/animated/spin";
import { loanCaptions, loanText, loanTypes } from "../../_helpers/language";
import { LanguageContext } from "../../services/language/language.context";

export const LoansPage = () => {
    const { appLanguage } = useContext(LanguageContext);
    return (
        <div className="app-body">
            <AppHeader />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="/images/pics/loan-personal.jpg " className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">{loanCaptions.c0[appLanguage]}</div>
                    <div className="page-body">
                        <LoanTypes data={loanTypes} lang={appLanguage} />
                        <Spinner>
                            <div className="account-details">
                                    <span className="topic-caption">
                                            {loanCaptions.c1[appLanguage]} 
                                    </span>
                                <div className="topic-container">
                                    <div className="topic-image-box">
                                        <img src="./images/pics/potato.jpg" alt="" className="topic-img" />
                                    </div>
                                    <div className="account-facts" id="agric-loan">
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSeedling} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l11[appLanguage]}</span>
                                        </div>
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSeedling} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l12[appLanguage]}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spinner>
                        <Spinner>
                            <div className="account-details">
                                    <span className="topic-caption">
                                            {loanCaptions.c2[appLanguage]} 
                                    </span>
                                <div className="topic-container">
                                    <div className="topic-image-box">
                                        <img src="./images/pics/Business-Loan-Eligibility.png" alt="" className="topic-img" />
                                    </div>
                                    <div className="account-facts" id="agric-loan">
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSuitcase} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l21[appLanguage]}</span>
                                        </div>
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSuitcase} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l22[appLanguage]}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spinner>
                        <Spinner>
                            <div className="account-details">
                                    <span className="topic-caption">
                                        {loanCaptions.c3[appLanguage]} 
                                    </span>
                                <div className="topic-container">
                                    <div className="topic-image-box">
                                        <img src="./images/pics/school-fees-loan.jpg" alt="" className="topic-img" />
                                    </div>
                                    <div className="account-facts" id="agric-loan">
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSeedling} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l31[appLanguage]}</span>
                                        </div>
                                        <div className="fact-box">
                                            <span className="fact-bullet"><FontAwesomeIcon icon={faSeedling} color="#2a4d5f" /></span>
                                            <span className="fact-text">{loanText.l32[appLanguage]}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spinner>
                    </div>
            </main>
            <AppFooter />
        </div>
    );
}
