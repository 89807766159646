import React, { useRef, useState } from "react";
import { faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight, faBold, faCheckDouble, faImage, faItalic, faLink, faList, faListOl, faUnderline } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addLinkUrl, addOrdList, addUnOrdList, alignLeft, alignRight, boldUnBlod, centerAlign, italics, justify, underline } from "../../_helpers/formatting";

export const BlogToolBar = ({ addImage }) => {
    const fileButton = useRef();

    const [isBold, setIsBold] = useState(false);
   
    const handleClick = () => {
        fileButton.current.click();
    }
    return (
        <div className="toolbar-container">
            <input 
                type="file" 
                className="pic-add-btn" 
                ref={fileButton}
                id="img-file" 
                onChange={addImage} 
            />
            <button className="tbr-btn" onClick={handleClick}>
                <FontAwesomeIcon icon={faImage} className="t-icon" />
            </button>
            <button 
                className="tbr-btn" 
                onClick={(e) => { 
                    boldUnBlod(e); 
                    setIsBold(!isBold);
                }}
                style={{
                    backgroundColor: isBold ? "#232323" : "#efefef",
                    color: isBold ? "goldenrod" : "#000000"
                }}   
            >
                <FontAwesomeIcon icon={faBold} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => italics(e) }>
                <FontAwesomeIcon icon={faItalic} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => underline(e) }>
                <FontAwesomeIcon icon={faUnderline} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => alignLeft(e)}>
                <FontAwesomeIcon icon={faAlignLeft} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => centerAlign(e)}>
                <FontAwesomeIcon icon={faAlignCenter} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => alignRight(e)}>
                <FontAwesomeIcon icon={faAlignRight} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => justify(e)}>
                <FontAwesomeIcon icon={faAlignJustify} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => addLinkUrl(e)}>
                <FontAwesomeIcon icon={faLink} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => addOrdList(e)}>
                <FontAwesomeIcon icon={faList} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => addUnOrdList(e)}>
                <FontAwesomeIcon icon={faListOl} className="t-icon" />
            </button>
            <button className="tbr-btn" onClick={(e) => addUnOrdList(e)}>
                <FontAwesomeIcon icon={faCheckDouble} className="t-icon" />
            </button>
        </div>
    );
}