import { useState } from "react";

import "../../styles/forms.css";
import { FormCaption } from "../../components/forms/forms-containers";
import { FormInput } from "../../components/forms/inputs";
import { FormSubmitButton } from "../../components/forms/buttons";

export const SendRecoveryMail = () => {

    const [email, setEmail] = useState(null);

    return (
        <div className="auth-page">
            <div className="form-container">
                <FormCaption caption="Send recovery email" />
                <form className="auth-form">
                    <FormInput
                        caption="Email"
                        type="email"
                        width="90%"
                        placeholder="mymail@provider.com"
                        id="email"
                        onChangevalue={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <FormSubmitButton
                        caption="Submit"
                        width="90%"
                        onPress={() => console.log(email)}
                    />
                </form>
            </div>
        </div>
    )
}