import classNames from "classnames";
import "../../styles/alert.css";

 export const SiteAlert = ({ caption, yes, no, yesMethod, noMethod, message}) => {
    return (
        <div className="alert-screen">
            <div className="alert-container">
                <div className="alert-content">
                    <div className="alert-caption">{caption}</div>
                    <div className="alert-text">{message}</div>
                    <div className="alert-buttons">
                       {yes && 
                        <button 
                                className={classNames({
                                    "alert-button": true,
                                    "dark-button": true
                                })}
                                onClick={yesMethod}
                        >
                            {yes}
                        </button>
                        }
                        {no && 
                        <button 
                            className={classNames({
                                "alert-button": true,
                                "light-button": true
                            })}
                            onClick={noMethod}
                        >
                            {no}
                        </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
 }