import { faBarChart, faLock, faSitemap, faUsers } from "@fortawesome/free-solid-svg-icons"

export const serviCardData = [
    {
        caption: {
            en: "Accounts",
            fr: "Comptes"
        },
        iconPath: "./images/svgs/savings.svg",
        uri: "/banking",
        text: {
            en: "AICCO FINANCE offers a wide range of accounts depending on the user's preference for letting us manage their money. We offer Salary accounts, Savings accounts, Term accounts, Current accounts and Children accounts",
            fr: "AICCO FINANCE propose une large gamme de comptes en fonction de la préférence de l'utilisateur pour nous laisser gérer son argent. Nous proposons des comptes de salaire, des comptes d'épargne, des comptes à terme, des comptes courants et des comptes pour enfants"
        },
    },
    {
        caption: {en: "Loans", fr: "Prets"},
        iconPath: "./images/svgs/loans.svg",
        uri: "/loans",
        text: {
            en: "We offer loans at very moderate and low interest rates to support small and medium size enterprises. Loans offered comprise of Agricultural loans, School Fees loans, Building loans and Buisness loans.",
            fr: "Nous proposons des prêts à des taux d'intérêt très modérés et bas pour soutenir les petites et moyennes entreprises. Les prêts proposés comprennent des prêts agricoles, des prêts pour les frais de scolarité, des prêts pour la construction et des prêts commerciaux."
        },
    },
    {
        caption: {en: "Daily savings", fr: "Épargne quotidienne"},
        iconPath: "./images/svgs/salary.svg",
        uri: "/banking",
        text: {
            en: "At AICCO Finance we offer very flexible options, one of which is the daily savings. This especially suits petit traders who can save small amounts on a daily basis. Daily savings will help the client save large amounts over time with relative ease.",
            fr: "Chez AICCO Finance, nous proposons des options très flexibles, dont l'épargne quotidienne. Cela convient particulièrement aux petits commerçants qui peuvent économiser de petites sommes au quotidien. L'épargne quotidienne aidera le client à économiser de grosses sommes au fil du temps avec une relative facilité."
        },
    },
    {
        caption: {en: "School fees loans", fr: "Prêts de scolarité"},
        iconPath: "./images/svgs/student.svg",
        uri: "/loans",
        text: {
            en: "School resumption has always been a very stressful period for parents, owing to the financial burden they would have to bear. AICCO is here to help with school fees loans at moderate interest rates.",
            fr: "La reprise des cours est toujours une période très stressante pour les parents, en raison de la charge financière qu'ils doivent supporter. L'AICCO est là pour vous aider en vous proposant des prêts pour les frais de scolarité à des taux d'intérêt modérés."
        },
    },
]

export const whyArray = [
    {
        icon: "./images/svgs/reliable.svg",
        caption: {en: "Reliability", fr: "Fiabilité"},
        text: {
        en: "We deliver on our promises and are always present to help our members grow financially, via a variety of our attractive servives.",
        fr: "Nous tenons nos promesses et sommes toujours présents pour aider nos membres à se développer financièrement, via une variété de nos services attractifs."
        }
    },
    {
        icon: "./images/svgs/flexible.svg",
        caption: {en: "Flexibility", fr: "Flexibilité"},
        text: {
            en: "We accept a variety of payment methods including MTM Momo and Orange Money. Deposits and withdrawals can be done via these channels.",
            fr: "Nous acceptons une variété de méthodes de paiement, notamment MTM Momo et Orange Money. Les dépôts et les retraits peuvent être effectués via ces canaux."
        }
    },
    {
        icon: "./images/svgs/confidential.svg",
        caption:{en: "Confidentiality", fr: "Confidentialité"},
        text: {
            en: "Only a member or autorized signatories has exclusive access to their accounts and its details, as we make sure that is the case.",
            fr: "Seul un membre ou les signataires autorisés ont un accès exclusif à leurs comptes et à leurs détails, car nous veillons à ce que ce soit le cas."
        }
    },
    {
        icon: "./images/svgs/proximity.svg",
        caption: {en: "Proximity", fr: "Proximité"},
        text: {
            en: "Our continuous growth has ensured that AICCO FINANCE now has branches in DOUALA, YAOUNDE, SANTA TOWN and MILE 12, so as to be easily accessible to its customers in these locations.",
            fr: "Notre croissance continue a permis à AICCO FINANCE de disposer désormais d’agences à DOUALA, YAOUNDE, SANTA TOWN et MILE 12, afin d’être facilement accessible à ses clients dans ces localités."
        }
    }
]

export const objectives = [
    {
        text: {
            en: "To act as a safe microfinance establishment.",
            fr: "Agir comme un établissement de microfinance sûr."
        },
        icon: faLock
    },
    {
        text: {
            en: "To provide a broad range of products and services in a sustainable and profitable manner.",
            fr: "Fournir une large gamme de produits et de services de manière durable et rentable."
        },
        icon: faSitemap
    },
    {
        text: {
            en: "To provide general membership eduaction, and training of board committee and staff.",
            fr: "Assurer l’éducation générale des membres et la formation du conseil d’administration, du comité et du personnel."
        },
        icon: faBarChart
    },
    {
        text: {
            en: "To practice the value of equity, equality and social concern for community.",
            fr: "Mettre en pratique la valeur de l’équité, de l’égalité et de la préoccupation sociale pour la communauté."
        },
        icon: faUsers
    },
    
    
]

export const branchData = [
    {
        name:  {
            en: "Santa/Home Branch",
            fr: "Agence de Santa"
        },
        location: "North West Region, Santa motor park, near Express Union Office",
        image: "/images/pics/homebranch.jpg",
        contact: "+237674815844"
    },
    {
        name: {
            en: "Yaounde branch",
            fr: "Agence de Yaoundé"
        },
        location: "Center Region, Yaounde, around Biyem Assi Lycee",
        image: "/images/pics/yaounde.jpg",
        contact: "+237675916521"
    },
    {
        name: {
            en: "Douala branch",
            fr: "Agence de Douala"
        },
        location: "Littoral Region, Douala, Bonaberi, around PCC Bonaberi",
        image: "/images/pics/Douala.jpg",
        contact: "+237651319715"
    },
    {
        name: {
            en: "Mile 12 Branch",
            fr: "Agence de Mile 12"
        },
        location: "Mile 12, Mbei, Santa, besides Douala vegetable market",
        image: "/images/pics/Douala.jpg",
        contact: "+237674815844"
    }
]

export const moneyLogos = [
    {
        path: "/images/logos/ria.png"
    },
    {
        path: "/images/logos/momo-mtn.png"
    },
    {
        path: "/images/logos/orange-money.png"
    },
    {
        path: "/images/logos/MoneyGram-Logo.png"
    },
    {
        path: "/images/logos/western-union.png"
    }
]

