export const FormInput = (props) => {
    return (
        <div className="input-container">
            <span className="input-caption">{props.caption}</span>
            <input 
                type={props.type} 
                className="input-field" 
                id={props.id}
                placeholder={props.placeholder}
                onChange={props.onChangevalue} 
            />
        </div>
    );
}