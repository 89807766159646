import { useState, createContext, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageContextProvider = ({ children }) => {

    /** state hooks */
    const [appLanguage, setLanguage] = useState("en");

    const switchAppLanguage = () => {
        appLanguage === "fr" ? setLanguage("en") : setLanguage("fr");
        appLanguage === "fr" ? 
        localStorage.setItem("lang", "en") :
        localStorage.setItem("lang", "fr");
    };

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
           setLanguage(lang);
        }else{
            return;
        }
    }, []);

    return (
        <LanguageContext.Provider
            value={{
                appLanguage,
                switchAppLanguage,
            }}
        >
            { children }
        </LanguageContext.Provider>
    );
}