import React, { useState, useContext } from "react";

import { FormCaption } from "../../components/forms/forms-containers";
import { FormSubmitButton } from "../../components/forms/buttons";
import { FormInput } from "../../components/forms/inputs";
import "../../styles/forms.css";
import { AuthenticationContext } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";


export const LoginPage = () => {
    /** State hooks */
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    

    const { loginUser, isLoading } = useContext(AuthenticationContext);

    
    return (
            <div className="auth-page">
                {isLoading && <LoadingIndicator />}
                <div className="form-container">
                    <FormCaption caption="Sign in" />
                    <form className="auth-form">
                        <FormInput
                            caption="Email"
                            type="email"
                            width="90%"
                            placeholder="mymail@provider.com"
                            id="email"
                            onChangevalue={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <FormInput
                            caption="Password"
                            type="password"
                            width="90%"
                            placeholder="Enter your password"
                            id="password"
                            onChangevalue={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                        <FormSubmitButton
                            caption="Sign in"
                            width="90%"
                            onPress={(e) => {
                                e.preventDefault();
                                loginUser(email,password);
                            }}
                        />
                    </form>
                    <div className="recovery-propmpt">
                        <span className="forgot">
                            <a href="/recoverymail" className="forgot-link">Forgot my password</a>
                        </span>
                    </div>
                </div>
            </div>
    )
}