import React, { useState } from "react";
//import parse from "html-react-parser"
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import "../../styles/blogs.css";
import "../../styles/gallery.css";
import { apiUrl, serverUrl } from "../../_helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCaretLeft, faCaretRight, faClose, faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { SiteAlert } from "../../components/utils/site-alert";



export const GalleryEditPage = () => {

     const { token, isLoading, userData, logoutUser} = useAuth();

 
    const [gallery, setGallery] = useState([]);
    const [seenPatch, setSeenPatch] = useState(null);
    const [dataSize, setDataSize] = useState(0);
    const [deleteId, setDeleteId] = useState(null);
    const [localLoad, setLoading] = useState(false);

    const [pgNum, setpgNum] = useState(1);

    
    const task = new URLSearchParams(window.location.search).get("task");
    

    const decrementPg = () => { setpgNum((num) => (num === 1 ? 1 : num - 1))};

    const incrementPg = (max) => setpgNum((num) => (num < max ?  num + 1 : num));


    const paginateData = (pgNum, data) => {
        const page = data.slice((5*pgNum) - 5, (5*pgNum));
        setGallery(page);
    }
   

    React.useEffect(() => { 
        const getGalleryPics = async () => {
            try {
                const response = await (fetch(`${serverUrl}/loadgallery`,{
                    method: "GET",
                    headers: {
                        Accept: "application/json"
                    }
                }));
                const data = await response.json();
                setDataSize(data.length);
                paginateData(pgNum,data);
                console.log(data);
            } catch (e) {
                console.log({error: `Unable to get pics! ${e}`});
            }
        }
       
        getGalleryPics();
        console.log(pgNum); 
    }, [pgNum]);

    const deleteImage = async (id, token) => {
        try {
            setLoading(true);
            const response = await fetch(`${serverUrl}/deleteimage?id=${id}`,{
                method: "GET",
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`
                },
            });
            const resp = await response.json();
            alert(resp.message);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log({error: `Failed to delete! ${e}`});
        }
    }

    return (
        <div className="app-body">
            {(isLoading || localLoad) && <LoadingIndicator />}
            {deleteId  && 
                <SiteAlert 
                    caption="Delete image"
                    message="Do you wish to permanently delete this image and its details? This action is irreversible!"
                    yes= "Delete"
                    no= "Cancel"
                    yesMethod= {() => {
                        deleteImage(deleteId, token);
                        setDeleteId(null);
                        window.location.reload();
                    }}
                    noMethod={() => setDeleteId(false)}
                />
            }
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/news.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Blog & Events</div>
                <div className="page-body">
                    
                   
                     <div>
                        <div className="mid-caption">Event gallery images</div>
                        <div className="gallery-container">
                            {gallery.map((event) => {
                                return (
                                    <div className="event-unit" key={event.id}>
                                        <div className="unit-cover">
                                            <div className="cover-buttons">
                                                <button 
                                                    className="cover-bttn" 
                                                    onClick={() => setSeenPatch(event.id)}
                                                >
                                                   <span>...</span>
                                                </button>
                                            </div>
                                            <div 
                                                className={classNames({
                                                    "more-flap": true,
                                                    "seen": parseInt(seenPatch) === parseInt(event.id)
                                                })}
                                                id={event.id}
                                            >
                                                <button 
                                                    className={classNames("close-bt", "more-bt")}
                                                    onClick={() => setSeenPatch(null)}
                                                >
                                                    <FontAwesomeIcon icon={faClose} className="flap-close" />
                                                </button>
                                                <button 
                                                    className="more-bt"
                                                    onClick={() => { 
                                                        task === "update" ?
                                                        window.location.href = `/editimage?id=${event.id}`:
                                                        setDeleteId(event.id);
                                                    }}
                                                >
                                                    {task === "update" ? 
                                                        <FontAwesomeIcon icon={faEdit} className="edit-image" /> :
                                                        <FontAwesomeIcon icon={faTrashCan} className="edit-image" />
                                                    }
                                                    
                                                </button> 
                                            </div>
                                        </div>
                                        <img src={`${apiUrl}/${event.picture}`} alt="" className="event-img"/>
                                    </div>
                                );
                            })

                            }
                            <div className="page-buttons">
                                <button className="paging-btn" onClick={() => decrementPg()}>
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                    <span className="pagin-cap">Previous</span>
                                </button>
                                <div className="pg-num" id="pg-num">{pgNum}</div>
                                <button className="paging-btn" onClick={() => incrementPg(Math.ceil(dataSize/5))}>
                                    <span className="pagin-cap">Next</span>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </button>
                            </div>
                        </div>
                       
                     </div>
                    
                    
                    
                </div>
            </main>
            <AppFooter />
        </div>
    );
}
