export const HeaderLabels = {
    lang: {
        en: "Francais",
        fr: "English"
    },
    home: {
        en: "Home",
        fr: "Acceuille"
    },
    banking: {
        en: "Banking",
        fr: "Opérations Bacaire"
    },
    about: {
        en: "About us",
        fr: "Infos"
    },
    loans:{
        en: "Loans",
        fr: "Prêts"
    },
    blog:{
        en: "Blogs/Events",
        fr: "Blogs/Événements"
    },
    login:{
        en: "sign in",
        fr: "connexion"
    },
    logout:{
        en: "logout",
        fr: "déconnexion"
    }

}

export const homeCaptions = {
    services: {
        en: "Accounts & services",
        fr: "Comptes & services"
    },
    mission: {
        en: "Our mission",
        fr: "Notre mission"
    },
    objectives: {
        en: "Our objectiives",
        fr: "Nos objectifs"
    },
    choice: {
        en: "Why clients choose us",
        fr: "Pourquoi les clients nous choisissent"
    },
    fininc: {
        en: "Financial inclusion",
        fr: "Inclusion financière"
    },
    incnote:{
        en: "Our goal is to provide financial services that will empower the people at grassroot levels, financing and supporting local economic activities such as agriculture and petit trading. We are also strong supporters of education and are active partners with various organizations who we aid in the process of paying worker salaries and wages.",
        fr: "Notre objectif est de fournir des services financiers qui permettront aux populations locales de se prendre en charge, en finançant et en soutenant des activités économiques locales telles que l'agriculture et le petit commerce. Nous soutenons également l'éducation et sommes des partenaires actifs de diverses organisations que nous aidons à payer les salaires des travailleurs."
    }
}

export const bankingInfo ={
    l11:{
        en: "Purchase two (2) shares of XAF 10,000 each",
        fr: "Acheter deux (2) actions de 10 000 XAF chacune"
    },
    l12:{
        en: "Pay solidarity fund at XAF 8,000 and building fund at XAF 5,000",
        fr: "Fonds de solidarité à hauteur de 8 000 XAF et fonds de construction à hauteur de 5 000 XAF"
    }
}

export const accountsText = {
    l11: {
        fr: "Il s'agit du capital qui produira des intérêts payables à la fin de l'année.",
        en: "This represents the capital which will yield interest payable at the end of the year."
    },
    l12: {
        en: "Loans can be obtained on this account, with amount depending on the amount of money in the saving account.",
        fr: "Des prêts peuvent être obtenus sur ce compte, dont le montant dépend du montant d'argent sur le compte d'épargne."
    },
    l13: {
        fr: "Les retraits sur les comptes d'épargne sans préavis étaient auparavant payants, mais sont désormais gratuits.",
        en: "Withdrawal from savings accounts without prior notification used to be charged, but is now free of charge."
    },
    l21: {
        en: "This account yields no interest for the saver.",
        fr: "Ce compte ne rapporte aucun intérêt à l’épargnant."
    },
    l22: {
        en: "A member can use this account to deposited and withdraw at will without incurring any fee.",
        fr: "Un membre peut utiliser ce compte pour déposer et retirer à volonté sans encourir de frais."
    },
    l31: {
        en: "Money can be saved in this account for withdrawal after a predetermined amount of time, for example, six (6) months.",
        fr: "L'argent peut être épargné sur ce compte pour être retiré après une période de temps prédéterminée, par exemple six (6) mois."
    },
    l32: {
        en: "Withdrawal before the predetermined duration may not be allowed.",
        fr: "Le retrait avant la durée prédéterminée ne peut être autorisé."
    },
    l33: {
        en: "This type of account, like savings yields interest.",
        fr: "Ce type de compte, comme le compte d'épargne, rapporte des intérêts."
    },
    l41: {
        en: "This is an account that a person opens to save money for the welfare of their child (children).",
        fr: "Il s'agit d'un compte qu'une personne ouvre pour économiser de l'argent pour le bien-être de son (ses) enfant(s)."
    },
    l42: {
        en: "It has the same characteristics as the savings account.",
        fr: "Il présente les mêmes caractéristiques que le compte épargne."
    },
    l51: {
        en: "Meetings, groups, NGOs and CIGs can use such an account.",
        fr: "Les réunions, les groupes, les ONG et les GIC peuvent utiliser un tel compte."
    },
    l52: {
        en: "The group account is identical in characteristics to the saving account.",
        fr: "Le compte groupe présente des caractéristiques identiques à celles du compte épargne."
    },
    l61: {
        en: "An employee of the public and private sector get thekir salries paid through their salary account.",
        fr: "Un employé du secteur public et privé reçoit son salaire via son compte salaire."
    },
    l62: {
        en: "Creation of a salry account comes at a small fee.",
        fr: "La création d'un compte salaire est payante."
    },
    l63: {
        en: "Salary accounts do not yield any interest.",
        fr: "Les comptes de salaire ne rapportent aucun intérêt."
    }
}

export const loanTypes = [
    {
        en: "Agricultural Loan",
        fr: "Prêt agricole"
    },
    {
        en: "Business Loan",
        fr: "Prêt aux entreprises"
    },
    {
        en: "School Fees Loan",
        fr: "Prêt pour frais de scolarité"
    }
]

export const loanCaptions = {
    c0:{
        en: "Loans and borrowing",
        fr: "Prêts et emprunts"
    },
    c1: {
        en: "Agricultural Loan",
        fr: "Prêt agricole"
    },
    c2: {
        en: "Business Loan",
        fr: "Prêt aux entreprises"
    },
    c3: {
        en: "School Fees Loan",
        fr: "Prêt pour frais de scolarité"
    }
}

export const loanText = {
    l11: {
        en: "Agricultural loans were designed to help farmers meet up with farm input expenses.",
        fr: "Les prêts agricoles ont été conçus pour aider les agriculteurs à faire face aux dépenses liées aux intrants agricoles."
    }, 
    l12:{
        en:"These loans are characterized by relatively low interest rates.",
        fr: "Ces prêts se caractérisent par des taux d’intérêt relativement bas."
    },
    l21: {
        en: "Business loans help aspiring business persons start up small and medium size enterprises.",
        fr: "Les prêts aux entreprises aident les futurs entrepreneurs à démarrer des petites et moyennes entreprises."
    },
    l22: {
        en: "They also help growing enterprises to reinforce already existing investment.",
        fr: "Ils aident également les entreprises en croissance à renforcer les investissements déjà existants."
    },
    l31: {
        en: "School fees loans were put in place to assist parents ease the financial burden placed upon them by the expenses they may incur at the start of academic year.",
        fr: "Les prêts pour frais de scolarité ont été mis en place pour aider les parents à alléger le fardeau financier que représentent les dépenses qu'ils peuvent engager au début de l'année scolaire."
    },
    l32: {
        en: "Securing the school needs of your children is guaranteed, as school fees loans are fast and easy to secure.",
        fr: "La sécurisation des besoins scolaires de vos enfants est garantie, car les prêts pour frais de scolarité sont rapides et faciles à obtenir."
    }
}

export const aboutText = {
    page: {
        en: "About AICCO FINANCE",
        fr: "À propos d'AICCO FINANCE"
    },
    info: {
        en: "AICCO (AYEMBE INVESTMENT CREDIT AND COOPERATIVE SOCIETY) is a financial cooperative owned and managed by its members. It is a category I microfinance institution with head office in Santa, North West Region of Cameroon. It was authorized by COBAC decision D-2017/333 of 24th of October 2017, MINFI decision 000276 of 5th of April 2018 and MINAGRI decision NW/CO/12/1560 of 1st of April 2013.",
        fr: "AICCO (AYEMBE INVESTMENT CREDIT AND COOPERATIVE SOCIETY) est une coopérative financière détenue et gérée par ses membres. C'est une institution de microfinance de catégorie I dont le siège social est à Santa, dans la région du Nord-Ouest du Cameroun. Elle a été agrée par décision COBAC D-2017/333 du 24 octobre 2017, décision MINFI 000276 du 5 avril 2018 et décision MINAGRI NW/CO/12/1560 du 1er avril 2013."
    },
    santa: {
        en: "Santa/Home Branch",
        fr: "Agence de Santa"
    },
    yde: {
        en: "Yaounde branch",
        fr: "Agence de Yaoundé"
    },
    dla: {
        en: "Douala branch",
        fr: "Agence de Douala"
    },
    m12:{
        en: "Mile 12 Branch",
        fr: "Agence de Mile 12"
    },
    cr: {
        en: "Creation",
        fr: "Création"
    },
    br: {
        en: "Branches",
        fr: "Agences"
    }
}