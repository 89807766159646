export const navigate = (uri) => {
    window.location.href = uri;
}

export const getUrlParams = (param) => {
    return new URLSearchParams(window.location.search).get(param);
}


export const ellipses = (str) => {
    const { length } = str;
    if (length < 50) {
        return str;
    }
    let restr = str.slice(0, 50);
    return restr.concat("...");
}