import classNames from "classnames";
import "../../styles/services.css";
import { useState } from "react";

export const ServiceCard = (props) => {

    const [active, setActive] = useState(false);

    return (
        <button 
            className="service-box" 
            href={props.uri}
            onClick={() => window.location.href = props.uri}
            onMouseOver={() => { setActive(true) }}
            onMouseLeave={() => { setActive(false) }}
        >
            <span className="service-icon">
              <img src={props.iconPath} width="50" height="50" alt="" />
            </span>
            <span className="service-caption">{props.caption}</span>
            <div className={classNames({"line": true, "enlarged": active})} />
            <p className="service-text">{props.text}</p>
        </button>
    );
}