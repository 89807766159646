import React, { useState } from "react";
import parse from "html-react-parser"
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import "../../styles/blogs.css";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { apiUrl, serverUrl } from "../../_helpers/config";
import { parseTime } from "../../_helpers/parse-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";


export const BlogPost = () => {

    const { token, isLoading, userData, logoutUser} = useAuth();
    const [blogImage, setBlogImage] = useState(null);
    const [blogContent, setBlogContent] = useState(null);
    const [blogTitle, setBlogTitle] = useState(null);
    const [blogDate, setBlogDate] = useState(null);
    const [blog, setBlog] = useState(null);
    const [comments, setComments] = useState([]);

    const [names, setNames] = useState(null);
    const [email, setEmail] = useState(null);
    const [comment, setComment] = useState(null);

    const getBlogById = async (id) => {
        try {
            const response = await fetch(`${serverUrl}/getbyhash?id=${id}`);
            const res = await response.json();
            console.log(res);
            
            setBlogImage(`${apiUrl}/${res.cover_pic}`);
            const parsedBody = parse(res.content);
            setBlogTitle(res.title);
            setBlogDate(res.saved_on);
            setBlogContent(parsedBody);
            setBlog(res);
        } catch (e) {
           console.log({error: `Unable to load blog! ${e}`}); 
        }
    }

    const getBlogComments = async (id) => {
        try {
           const response = await fetch(`${serverUrl}/getblogcomments?id=${id}`,{
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
            }
           });
           const data = await response.json();
           setComments(data);
           console.log(data);
        } catch (e) {
            console.log({error: `No comments! ${e}`});
        }
    }

    const postComment = async (id) => {
        try {
            const response = await fetch(`${serverUrl}/comment?id=${id}`, {
                method: "POST",
                body: JSON.stringify({ names, email, comment }),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            });
            const res = await response.json();
            console.log(res);
            alert(res.message);
        } catch (e) {
            console.log({error: `Comment upload failed! ${e}`})
        }
    }

    const id = new URLSearchParams(window.location.search).get("id");

    React.useEffect(() => {
        
        getBlogById(id);
        getBlogComments(id);
    }, [id]);

    return (
        <div className="app-body">
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
             {isLoading && <LoadingIndicator />}
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/news.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Blog & Events</div>
                <div className="page-body">
                    <div className="blog-details">
                        <img src={blogImage} alt="" className="blog-image" />
                        <div className="blog-det-title">{blogTitle}</div>
                        <div className="blog-data">
                            <span className="data-unit">Date: {parseTime(blogDate)}</span>
                            <div className="separator" />
                            <span className="data-unit">Author: {blog?.names}</span>
                            <div className="separator" />
                            <span className="data-unit">Comments: {comments.length}</span>
                        </div>
                        <hr/>
                        <div className="blog-body">
                            {blogContent}
                        </div>
                        <div className="share-prompt">
                            <div>
                                <span className="share-icon">
                                    <FontAwesomeIcon icon={faShareNodes} />
                                </span>
                                <span className="prompt-caption">Share</span>
                            </div>
                            <span className="propt-buttons">
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faXTwitter} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </button>
                                <button className="prompt-button">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="comments">
                        <div className="comment-caption">{comments.length} Comments</div>
                        <div className="comments-section">
                            {comments.map((comment, i) => {
                                return(
                                    <div className="comm-unit" key={i}>
                                        <div className="comm-data">
                                            <span className="comm-meta meta-cap">{comment.names}</span>
                                            <span className="comm-meta">{(comment.posted_on)}</span>
                                        </div>
                                        <div className="comm-text">{comment.comment}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="comments-input">
                            <div className="comment-caption">Leave a comment</div>
                            <div className="comments-form">
                                <div className="comments-input-group">
                                    <span className="input-caption">Your Names</span>
                                    <input type="text" id="name" className="text-input" onChange={(e) => setNames(e.target.value)}  />
                                </div>
                                <div className="comments-input-group">
                                    <span className="input-caption">Your Email</span>
                                    <input type="text" id="name" className="text-input" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="comments-input-group">
                                    <textarea 
                                        className={classNames("text-input", "long-text")} 
                                        rows={30}
                                        placeholder="Type your comment here"
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                                <button className="submit-button" onClick={(e) => postComment(id)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <AppFooter />
        </div>   
    );
}