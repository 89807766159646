import React, { useState } from "react";
//import parse from "html-react-parser"
import classNames from "classnames";

import { AppHeader } from "../../components/headers/header";
import { AppFooter } from "../../components/footers/footer";
import "../../styles/infos.css";
import "../../styles/blogs.css";
import "../../styles/gallery.css";
import { apiUrl, serverUrl } from "../../_helpers/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-regular-svg-icons";
import { faCaretLeft, faCaretRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { parseTime } from "../../_helpers/parse-time";
import { useAuth } from "../../services/authentication/authentication.context";
import { LoadingIndicator } from "../../components/utils/loader";
import { ellipses } from "../../_helpers/aux-functions";


export const BlogPage = () => {

     const { token, isLoading, userData, logoutUser} = useAuth();

    const [blogposts, setBlogs] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [viewBlogs, setViewBlogs] = useState(true);
    const [seenPatch, setSeenPatch] = useState(null);
    const [dataSize, setDataSize] = useState(0);

    const [pgNum, setpgNum] = useState(1);

    const getAllBlogs = async () => {
        try {
            const response = await fetch(`${serverUrl}/blogs`,{
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json"
                }
            });
            const blogs = await response.json();
            setBlogs(blogs);
            console.log(blogs);
        } catch (e) {
            console.log({error: `Unable to get blogs! ${e}`});
        }
    }

    

    const decrementPg = () => { setpgNum((num) => (num === 1 ? 1 : num - 1))};

    const incrementPg = (max) => setpgNum((num) => (num < max ?  num + 1 : num));


    const paginateData = (pgNum, data) => {
        const page = data.slice((5*pgNum) - 5, (5*pgNum));
        setGallery(page);
    }
   

    React.useEffect(() => { 
        const getGalleryPics = async () => {
            try {
                const response = await (fetch(`${serverUrl}/loadgallery`,{
                    method: "GET",
                    headers: {
                        Accept: "application/json"
                    }
                }));
                const data = await response.json();
                setDataSize(data.length);
                paginateData(pgNum,data);
                console.log(data);
            } catch (e) {
                console.log({error: `Unable to get pics! ${e}`});
            }
        }
        getAllBlogs();
        getGalleryPics(); 
    }, [pgNum]);

    return (
        <div className="app-body">
            {isLoading && <LoadingIndicator />}
            <AppHeader data={userData} auth={token} signout={() => logoutUser(token)} />
            <main className={classNames("info-page", "app-main")}>
                <div className="page-cover" >
                    <div className="cover-layer" />
                    <div className="page-pic">
                        <img src="./images/pics/news.jpg" className="home-pic" alt="home" />
                    </div>
                </div>
                <div className="pic-caption">Blog & Events</div>
                <div className="page-body">
                    <div className="page-toggle">
                        <button 
                            className={classNames({
                                "toggle-btn": true,
                                "current-page": viewBlogs === true
                            })}
                            onClick={() => setViewBlogs(!viewBlogs)}
                        >
                            Blogs
                        </button>
                        <button 
                            className = {classNames({
                                "toggle-btn": true,
                                "current-page": viewBlogs === false
                            })}
                            onClick={() => setViewBlogs(!viewBlogs)}
                        >
                            Gallery
                        </button>
                    </div>
                    {!viewBlogs &&
                     <div>
                        <div className="mid-caption">Event gallery images</div>
                        <div className="gallery-container">
                            {gallery.map((event) => {
                                return (
                                    <div className="event-unit" key={event.id}>
                                        <div className="unit-cover">
                                            <div className="cover-buttons">
                                                <button 
                                                    className="cover-bttn" 
                                                    onClick={() => setSeenPatch(event.id)}
                                                >
                                                   <span>...</span>
                                                </button>
                                            </div>
                                            <div 
                                                className={classNames({
                                                    "more-flap": true,
                                                    "seen": parseInt(seenPatch) === parseInt(event.id)
                                                })}
                                                id={event.id}
                                            >
                                                <button 
                                                    className={classNames("close-bt", "more-bt")}
                                                    onClick={() => setSeenPatch(null)}
                                                >
                                                    <FontAwesomeIcon icon={faClose} className="flap-close" />
                                                </button>
                                                <button 
                                                    className="more-bt"
                                                        onClick={() => { 
                                                        window.location.href = `/eventdetail?eventid=${event.id}`
                                                    }}
                                                >
                                                    See more
                                                </button>
                                            </div>
                                        </div>
                                        <img src={`${apiUrl}/${event.picture}`} alt="" className="event-img"/>
                                    </div>
                                );
                            })

                            }
                            <div className="page-buttons">
                                <button className="paging-btn" onClick={() => decrementPg()}>
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                    <span className="pagin-cap">Previous</span>
                                </button>
                                <div className="pg-num" id="pg-num">{pgNum}</div>
                                <button className="paging-btn" onClick={() => incrementPg(Math.ceil(dataSize/5))}>
                                    <span className="pagin-cap">Next</span>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </button>
                            </div>
                        </div>
                       
                     </div>
                    }
                    {viewBlogs &&
                    <div>
                        <div className="mid-caption">Recently posted blog posts</div>
                        <div className="blogs-container">
                            {blogposts.map((blogpost, key) => {
                               
                                return (
                                    <div className="blog-unit" key={key}>
                                    <div className="cover-plate">
                                        <div className="padded">
                                            <div 
                                                className="blog-title" 
                                                onClick={() => {window.location.href = `/blogpost?id=${blogpost.id}`}}
                                            >
                                                {ellipses(blogpost.title)}
                                            </div>
                                            <div className="blog-dets">
                                                <span className="det-unit">
                                                    <span className="det-caption">by</span>
                                                    <span className="det-text">{blogpost.names}</span>
                                                </span>
                                                <span className="det-unit">
                                                    <FontAwesomeIcon icon={faClockFour} className="det-caption" />
                                                    <span className="det-text">{parseTime(blogpost.saved_on)}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="image-block">
                                        <img src={`${apiUrl}/${blogpost.cover_pic}`} alt="" className="block-img"/>
                                    </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </main>
            <AppFooter />
        </div>
    );
}
